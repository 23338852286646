import React from 'react'
import Head from 'react-helmet'

import Hero from './Hero'
import Form from './Form'
import { Container, createStyles, withStyles } from '@material-ui/core'
import { navPadding } from '../components/Layout/Nav'
import Layout from '../components/Layout'

const styles = (theme) => {
  return createStyles({
    container: {
      maxWidth: '42.1875rem',
      paddingLeft: navPadding.left,
      paddingRight: navPadding.right,
      [theme.breakpoints.up('md')]: {
        padding: 0,
      },
    },
  })
}

const Contact = ({ classes }) => (
  <Layout>
    <Head>
      <title>Contact</title>
    </Head>
    <Container className={classes.container}>
      <Hero />
      <Form />
    </Container>
  </Layout>
)

export default withStyles(styles)(Contact)
