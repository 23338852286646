import React from 'react'
import {
  Button as BaseButton,
  Typography,
  createStyles,
  withStyles,
} from '@material-ui/core'

export const getButtonStyles = () => ({
  button: {
    padding: '0.25rem 1rem',
    borderRadius: '6.25rem',
  },
  text: {
    lineHeight: '1.0669rem',
    fontWeight: 700,
    fontSize: '0.875rem',
  },
})

const styles = () => {
  const buttonStyles = getButtonStyles()
  return createStyles(buttonStyles)
}

export const Button = ({ classes, children, ...other }) => {
  return (
    <BaseButton className={classes.button} {...other}>
      <Typography className={classes.text} variant="button">
        {children}
      </Typography>
    </BaseButton>
  )
}

export default withStyles(styles)(Button)
