import React from 'react'
import { fields } from '../../constants/contact'

import InputGroup from './InputGroup'
import Input from './InputGroup/Input'

const EmailInput = (props) => (
  <Input
    label="Email Address"
    {...props}
    inputProps={{ placeholder: 'Enter Email' }}
  />
)

const Email = () => (
  <InputGroup name={fields.email} type="email" Input={EmailInput} />
)

export default Email
