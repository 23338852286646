import React from 'react'
import { fields } from '../../constants/contact'
import { withStyles } from '@material-ui/core'

import InputGroup from './InputGroup'
import { Input, getStyles } from './InputGroup/Input'

const styles = (theme) => {
  const inputStyles = getStyles(theme)
  return {
    ...inputStyles,
    outlinedInput: {
      ...inputStyles.outlinedInput,
      borderRadius: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  }
}

const QuestionInput = (props) => (
  <Input
    label="Question"
    inputProps={{ multiline: true, rows: 10, placeholder: 'Enter Question' }}
    {...props}
  />
)

const StyledQuestionInput = withStyles(styles)(QuestionInput)

const Question = () => (
  <InputGroup name={fields.question} type="text" Input={StyledQuestionInput} />
)

export default Question
