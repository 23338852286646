import React from 'react'
import { fields } from '../../constants/contact'

import InputGroup from './InputGroup'
import Input from './InputGroup/Input'

const SubjectInput = (props) => (
  <Input
    label="Subject"
    {...props}
    inputProps={{ placeholder: 'Enter Subject' }}
  />
)

const Subject = () => (
  <InputGroup name={fields.subject} type="text" Input={SubjectInput} />
)

export default Subject
