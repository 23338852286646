import React from 'react'
import { reduxForm } from 'redux-form'
import { Typography } from '@material-ui/core'

import Email from './Email'
import Subject from './Subject'
import Question from './Question'
import SubmitButton from './SubmitButton'
import { contactFormId } from '../../constants/contact'

const FormSection = () => {
  return (
    <section>
      <form>
        <Email />
        <Subject />
        <Question />
        <Typography>
          Please enter the details of your request. A member of our support
          staff will respond as soon as possible.
        </Typography>
        <SubmitButton />
      </form>
    </section>
  )
}

export default reduxForm({
  form: contactFormId,
})(FormSection)
