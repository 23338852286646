import React from 'react'
import { Typography, createStyles, withStyles } from '@material-ui/core'

import { getHeroStyles } from '../styles/hero'

const styles = (theme) => {
  const heroStyles = getHeroStyles()
  return createStyles({
    hero: {
      ...heroStyles,
      marginBottom: '3.3125rem',
      [theme.breakpoints.up('md')]: {
        marginBottom: '7.9375rem',
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: '5.125rem',
      },
    },
    title: {
      [theme.breakpoints.up('md')]: {
        textAlign: 'center',
      },
    },
  })
}

const Hero = ({ classes }) => {
  return (
    <section className={classes.hero}>
      <Typography className={classes.title} variant="h2">
        Contact Us
      </Typography>
    </section>
  )
}

export default withStyles(styles)(Hero)
