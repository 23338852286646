import React from 'react'
import {
  Typography,
  OutlinedInput,
  InputLabel,
  createStyles,
  withStyles,
} from '@material-ui/core'

export const getStyles = (theme) => {
  const borderWidth = '0.0187rem'

  const borderStyles = {
    borderRadius: '3.125rem',
    border: `${borderWidth} solid #C6C6C6`,
  }

  return {
    group: {
      marginBottom: '1.8125rem',
      [theme.breakpoints.up('md')]: {
        marginBottom: '1.75rem',
      },
    },
    outlinedInput: {
      boxShadow: '2px 2px 10px #F7F7F7',
      width: '100%',
      ...borderStyles,
      fontWeight: 'bold',
    },
    focused: {
      border: `${borderWidth} solid ${theme.palette.primary.main}`,
      boxShadow: 'rgba(255, 0, 128, 0.1)',
    },
    input: {
      padding: '0.875rem 1.4375rem',
      '&::placeholder': {
        fontWeight: 400,
        fontSize: '0.75rem',
        color: '#e1e1e1',
      },
    },
    multiline: {
      padding: 0,
      fontWeight: '400 !important',
    },
    notchedOutline: {
      border: 0,
    },
    label: {
      marginBottom: '0.875rem',
      display: 'flex',
    },
    labelText: {
      fontSize: '0.875rem',
      color: 'rgba(68, 68, 68, 1)',
    },
    requiredSymbol: {
      textAlign: 'top',
      color: theme.palette.primary.main,
      marginLeft: '0.25rem',
    },
  }
}

const styles = (theme) => {
  const inputStyles = getStyles(theme)
  return createStyles(inputStyles)
}

export const Input = ({
  classes,
  label,
  input: { onChange, value },
  inputProps,
}) => {
  const handleChange = (event) => onChange(event.target.value)
  return (
    <div className={classes.group}>
      <InputLabel className={classes.label}>
        <Typography className={classes.labelText}>{label}</Typography>
        <Typography className={classes.requiredSymbol}>*</Typography>
      </InputLabel>
      <OutlinedInput
        onChange={handleChange}
        value={value}
        classes={{
          notchedOutline: classes.notchedOutline,
          root: classes.outlinedInput,
          input: classes.input,
          focused: classes.focused,
          multiline: classes.multiline,
        }}
        {...inputProps}
      />
    </div>
  )
}

export default withStyles(styles)(Input)
