import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'

import Button, { getButtonStyles } from '../../components/Button'

const styles = (theme) => {
  const buttonStyles = getButtonStyles()
  return createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '2rem',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
      },
    },
    button: {
      ...buttonStyles.button,
      width: '8.8994rem',
      padding: '0.6875rem 0',
    },
  })
}

const ButtonContainer = ({ classes }) => {
  return (
    <div className={classes.container}>
      <Button className={classes.button} variant="contained" color="primary">
        Submit
      </Button>
    </div>
  )
}

export default withStyles(styles)(ButtonContainer)
